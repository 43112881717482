import { ImagesLocalization } from './types';
import logo from '../assets/logo.png';
import logo_white from '../assets/logo_white.png';

const images: ImagesLocalization = {
  logo: {
    DE: logo,
    CH: logo,
  },
  logo_white: {
    DE: logo_white,
    CH: logo_white,
  },
};

export default images;
